import { Controller } from '@graphcommerce/ecommerce-ui'
import { useFormAddProductsToCart } from '@graphcommerce/magento-product'
import { RenderType, TypeRenderer } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { MenuItem, TextField } from '@mui/material'
import { useEffect } from 'react'
import { PackSizeDropdownFragment } from './PackSizeDropdown.gql'

type OptionRenderer = TypeRenderer<
  NonNullable<NonNullable<PackSizeDropdownFragment['options']>[number]> & {
    index: number
    optionIndex: number
  }
>

const CustomizablePackagingOption: OptionRenderer['CustomizablePackagingOption'] = (props) => {
  const { value, title, required, index, optionIndex } = props

  const { control, setValue } = useFormAddProductsToCart()
  const fieldName = `cartItems.${index}.selected_options.${optionIndex}` as const

  const defaultValue = !required ? '1' : value?.[0]?.uid

  useEffect(() => {
    if (defaultValue && required) setValue(fieldName, defaultValue)
  }, [defaultValue, fieldName, required, setValue, value])

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value: val, onChange } }) => (
        <TextField
          value={value?.find((i) => i?.uid === val) ? val : defaultValue}
          onChange={onChange}
          select
          label={title}
          SelectProps={{ notched: true }}
          sx={{
            width: '100%',
            maxWidth: '200px',
            marginLeft: { xs: '0px', lg: '10px' },
          }}
          size='small'
          variant='outlined'
        >
          {!required && (
            <MenuItem key='1' value='1'>
              <Trans id='Per stuk' />
            </MenuItem>
          )}
          {value?.map((v) => {
            if (!v?.uid || !v.title) return null
            return (
              <MenuItem key={v.uid} value={v.uid}>
                {v.title[0].toUpperCase() + v.title.slice(1)}
              </MenuItem>
            )
          })}
        </TextField>
      )}
    />
  )
}

const notImplemented = () => <div>not implemented</div>

const renderer: OptionRenderer = {
  CustomizableAreaOption: notImplemented,
  CustomizablePackagingOption,
  CustomizableCheckboxOption: notImplemented,
  CustomizableDateOption: notImplemented,
  CustomizableDropDownOption: notImplemented,
  CustomizableFieldOption: notImplemented,
  CustomizableFileOption: notImplemented,
  CustomizableMultipleOption: notImplemented,
  CustomizableRadioOption: notImplemented,
}

export function PackSizeDropdown(props: PackSizeDropdownFragment & { index: number }) {
  const { options, index } = props
  return (
    <>
      {options?.map((option) => {
        if (!option) return null
        return (
          <RenderType
            key={option.uid}
            renderer={renderer}
            {...option}
            index={index}
            optionIndex={0}
          />
        )
      })}
    </>
  )
}
