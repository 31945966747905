import { Scroller, ScrollerButton, ScrollerProvider } from '@graphcommerce/framer-scroller'
import { Image } from '@graphcommerce/image'
import {
  iconChevronLeft,
  iconChevronRight,
  responsiveVal,
  IconSvg,
  useFabSize,
} from '@graphcommerce/next-ui'
import { Link, Typography, Box, Container } from '@mui/material'
import { useRouter } from 'next/router'
import { RowProductFragment } from '../RowProduct.gql'

export const ITEM_SLIDER_ID = 'item-slider'

export function ItemSlider(props: RowProductFragment) {
  const { pageLinks, title } = props
  const router = useRouter()
  const isHome = router.pathname === '/'
  const size = useFabSize('responsive')

  return (
    <Box sx={(theme) => ({ background: theme.palette.background.default })}>
      <Box
        id={ITEM_SLIDER_ID}
        sx={(theme) => ({
          color: theme.palette.text.primary,
          position: 'relative',
          maxWidth: 'fit-content',
          mx: 'auto',
        })}
      >
        {!isHome && (
          <Container maxWidth={false}>
            <Typography component='h2' variant='h3' display='inline'>
              {title}
              <Typography component='span' variant='h3' color='primary'>
                .
              </Typography>
            </Typography>
          </Container>
        )}
        <ScrollerProvider scrollSnapAlign='none'>
          <Scroller
            hideScrollbar
            sx={(theme) => ({
              columnGap: { xs: theme.spacings.md, md: theme.spacings.lg },
              px: theme.page.horizontal,
              py: isHome ? theme.spacings.lg : theme.spacings.xxs,
              mb: isHome ? 0 : theme.spacings.lg,
              gridAutoColumns: isHome ? responsiveVal(120, 140) : responsiveVal(120, 180),
            })}
          >
            {pageLinks.map((pageLink) => (
              <Link
                href={pageLink.url.startsWith('/') ? pageLink.url : `/${pageLink.url}`}
                key={pageLink.url}
                underline='none'
                color='inherit'
                sx={(theme) => ({
                  display: 'grid',
                  justifyItems: 'center',
                  alignContent: 'start',
                  gap: theme.spacings.xs,
                })}
              >
                <Box
                  sx={(theme) => ({
                    overflow: 'hidden',
                    borderRadius: '100%',
                    width: '100%',
                    aspectRatio: '1/1',
                    border: `4px solid transparent`,
                    '&:hover': {
                      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
                    },
                  })}
                >
                  {pageLink?.asset && (
                    <Image
                      layout='fill'
                      src={pageLink.asset.url}
                      sx={{ objectFit: 'cover' }}
                      alt={pageLink.asset.alt || ''}
                      sizes='140px'
                    />
                  )}
                </Box>
                <Typography component='span' variant='subtitle2' fontWeight='bold'>
                  {pageLink.title}
                </Typography>
              </Link>
            ))}
          </Scroller>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gap: theme.spacings.xxs,
              position: 'absolute',
              left: theme.spacings.sm,
              top: `calc(45% - (${size}/2))`,
            })}
          >
            <ScrollerButton direction='left' showButtons='auto' size='responsive' color='primary'>
              <IconSvg src={iconChevronLeft} size='large' />
            </ScrollerButton>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'grid',
              gap: theme.spacings.xxs,
              position: 'absolute',
              right: theme.spacings.sm,
              top: `calc(45% - (${size}/2))`,
            })}
          >
            <ScrollerButton direction='right' showButtons='auto' size='responsive' color='primary'>
              <IconSvg src={iconChevronRight} size='large' />
            </ScrollerButton>
          </Box>
        </ScrollerProvider>
      </Box>
    </Box>
  )
}
